<div class="layout-wrapper" [ngClass]="containerClass">
    <app-topbar></app-topbar>
    
    <div class="layout-main-container">
        <div class="layout-main">
            <router-outlet></router-outlet>
        </div>
       
    </div>
  
    <div class="layout-mask"></div>
</div>
