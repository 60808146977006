import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { PesaturaComponent } from './components/pesatura/pesatura.component';
import { LoginComponent } from './components/login/login.component';
import { FormsModule } from "@angular/forms";
import { CommonModule } from '@angular/common';
import { ResponseInterceptorService} from "../app/services/response-interceptor.service";
import { MenuModule } from 'primeng/menu';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { StyleClassModule } from 'primeng/styleclass';
import { PanelMenuModule } from 'primeng/panelmenu';
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { DropdownModule } from "primeng/dropdown";
import { PasswordModule } from "primeng/password";
import { CardModule } from "primeng/card";
import { ChartModule } from "primeng/chart";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { CheckboxModule } from "primeng/checkbox";
import { ListboxModule } from "primeng/listbox";
import { TreeModule } from "primeng/tree";
import { DialogModule } from "primeng/dialog";
import { KeyFilterModule } from "primeng/keyfilter";
import { InputSwitchModule } from "primeng/inputswitch";
import { ToastModule } from "primeng/toast";
import { CarouselModule } from "primeng/carousel";
import { CalendarModule } from "primeng/calendar";
import { PanelModule } from "primeng/panel";
import { SelectButtonModule } from "primeng/selectbutton";
import { SpinnerModule } from "primeng/spinner";
import { InputMaskModule } from "primeng/inputmask";
import { TabViewModule } from "primeng/tabview";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { ToggleButtonModule } from "primeng/togglebutton";
import { AccordionModule } from "primeng/accordion";
import { FileUploadModule } from "primeng/fileupload";
import { RadioButtonModule } from "primeng/radiobutton";
import { DataViewModule } from "primeng/dataview";
import { VirtualScrollerModule } from "primeng/virtualscroller";
import { InputNumberModule } from "primeng/inputnumber";
import { TooltipModule } from "primeng/tooltip";
import { MultiSelectModule } from "primeng/multiselect";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { SidebarModule } from "primeng/sidebar";
import { DragDropModule } from "primeng/dragdrop";
import { ProgressBarModule } from "primeng/progressbar";
import { GalleriaModule } from "primeng/galleria";
import { SplitButtonModule } from "primeng/splitbutton";
import { ColorPickerModule } from "primeng/colorpicker";
import { TabMenuModule } from "primeng/tabmenu";
import { MegaMenuModule } from "primeng/megamenu";
import { OrganizationChartModule } from "primeng/organizationchart";
import { PickListModule } from "primeng/picklist";
import { SlideMenuModule } from "primeng/slidemenu";
import { BlockUIModule } from "primeng/blockui";
import { MenubarModule } from "primeng/menubar";
import { RippleModule } from "primeng/ripple";
import { AuthGuard } from './guards/auth.guard';

import {FieldsetModule} from 'primeng/fieldset';
import {AutoCompleteModule} from 'primeng/autocomplete';

import { TreeTableModule } from 'primeng/treetable';
import {ChipsModule} from 'primeng/chips';
import { FormazionepalletComponent } from './components/formazionepallet/formazionepallet.component';
import { CambiostatoComponent } from './components/cambiostato/cambiostato.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ShowerrorComponent } from './components/showerror/showerror.component';
import {
    HttpClient,
    HttpClientModule,
    HTTP_INTERCEPTORS,
  } from "@angular/common/http";
import { AltreazioniComponent } from './components/altreazioni/altreazioni.component';

@NgModule({
    declarations: [
        AppComponent,  
        PesaturaComponent,
        LoginComponent,
        FormazionepalletComponent,
        CambiostatoComponent,
        LogoutComponent,
        ShowerrorComponent,
        AltreazioniComponent
    ],
    imports: [
        AppRoutingModule,
        FormsModule,
        CommonModule,
        TableModule,
        InputTextModule,
        InputTextareaModule,
        DropdownModule,
        ButtonModule,
        PasswordModule,
        CardModule,
        PanelMenuModule,
        ChartModule,
        ProgressSpinnerModule,
        MessagesModule,
        MessageModule,
        BreadcrumbModule,
        CheckboxModule,
        ListboxModule,
        TreeModule,
        DialogModule,
        KeyFilterModule,
        InputSwitchModule,
        ToastModule,
        CarouselModule,
        CalendarModule,
        PanelModule,
        SelectButtonModule,
        SpinnerModule,
        InputMaskModule,
        TabViewModule,
        ScrollPanelModule,
        ToggleButtonModule,
        AccordionModule,
        FileUploadModule,
        RadioButtonModule,
        DataViewModule,
        VirtualScrollerModule,
        InputNumberModule,
        TooltipModule,
        MultiSelectModule,
        ConfirmDialogModule,
        OverlayPanelModule,
        SidebarModule,
        DragDropModule,
        ProgressBarModule,
        GalleriaModule,
        SplitButtonModule,
        ColorPickerModule,
        TabMenuModule,
        MegaMenuModule,
        OrganizationChartModule,
        PickListModule,
        SlideMenuModule,
        BlockUIModule,
        MenubarModule,
        RippleModule,
        FieldsetModule,
        AutoCompleteModule,
        TreeTableModule,
        ChipsModule,
        StyleClassModule,
        PanelMenuModule,
        AppLayoutModule
    ],
    providers: [
        { provide: LocationStrategy, 
            useClass: HashLocationStrategy },
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ResponseInterceptorService,
            multi: true,
          },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
