import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { GlobalServiceService } from 'src/app/services/global-service.service';

@Component({
  selector: 'app-formazionepallet',
  templateUrl: './formazionepallet.component.html',
  styleUrls: ['./formazionepallet.component.scss'],
  providers: [ConfirmationService, MessageService]

})
export class FormazionepalletComponent implements OnInit {
  @ViewChild('barcodefield') barcodefield!: ElementRef;

  listaBobine:any[]=[];
  
  isBarCodeFocus:boolean=false;
  constructor(
    public globalservice:GlobalServiceService,
    private route: ActivatedRoute,
    private router:Router,
    private confirmationService: ConfirmationService, 
    private messageService: MessageService) {}

    ngOnInit(): void {
      this.globalservice.barcode='';
      this.globalservice.setActiveFunction(4);
      let params={
        idSessione:localStorage.getItem('idSessione')
      };
   
      this.globalservice.post("GetFormazionePallet",params).subscribe((data: { codEsito: number,desEsito:string,listaBobine:any[]}) => {
        if(data.codEsito==1){
    
          this.globalservice.msgsError =data.desEsito;
          this.globalservice.showError=true;

        } else {
          if(data.listaBobine){
            this.listaBobine=data.listaBobine;
        
          }
        }
        setTimeout(()=>{ 
          this.barcodefield.nativeElement.focus();
        },100);  
      });
   
    }
  


  onKey(event: any) {
    if (event.key === 'Enter') {
      this.globalservice.validateBarcode(4)?.subscribe((data: { codEsito: number; barcodeType: number; })=>{
        if((data.codEsito!=1)&&( data.barcodeType==6)){
          let params={
            idSessione:localStorage.getItem('idSessione'),
            barcode:this.globalservice.barcode
          };
          this.globalservice.post("AggiungiADistinta",params).subscribe((data: { codEsito: number,desEsito:string}) => {
            if(data.codEsito==1){
        
              this.globalservice.msgsError =data.desEsito;
              this.globalservice.showError=true;
              this.globalservice.barcode='';
      
            } else {
             this.globalservice.post("GetFormazionePallet",params).subscribe((data: { codEsito: number,desEsito:string,listaBobine:any[]}) => {
              if(data.codEsito==1){
          
                this.globalservice.msgsError =data.desEsito;
                this.globalservice.showError=true;
                this.globalservice.barcode='';
              } else {
                if(data.listaBobine){
                  this.listaBobine=data.listaBobine;
                  this.globalservice.barcode='';
                }
              }
              setTimeout(()=>{ 
                this.barcodefield.nativeElement.focus();
              },100);  
            });
            }
          });
        }
      });
    } else {
      this.globalservice.barcode=event.target.value;
    }
  }

  onFocus(){
    this.isBarCodeFocus=true; 
 
  }

  onBlur(){
    this.barcodefield.nativeElement.focus();
    this.isBarCodeFocus=false; 
    
  }
  AnnullaDistinta(){
    let params={
      idSessione:localStorage.getItem('idSessione'),
    };
    this.globalservice.post("AnnullaDistinta",params).subscribe((dataconferma: { codEsito: number,domandaConferma:string }) => {
      if(dataconferma.codEsito==0){
        this.confirmationService.confirm({
          header: this.globalservice.titoloalert,
          message: dataconferma.domandaConferma,
          accept: () => {
            this.globalservice.post("ConfermaAnnullaDistinta",params).subscribe((dataRistampa: { codEsito: number,domandaConferma:string }) => {
              this.listaBobine=[];
            });
          },
          reject: () => {
          
          }
        });
      }
    });  
  
  }

  FineDistinta(){
    let params={
      idSessione:localStorage.getItem('idSessione'),
    };
    this.globalservice.post("FineDistinta",params).subscribe((dataconferma: { codEsito: number,domandaConferma:string }) => {
      if(dataconferma.codEsito==0){
        this.confirmationService.confirm({
          header: this.globalservice.titoloalert,
          message: dataconferma.domandaConferma,
          accept: () => {
            this.globalservice.post("ConfermaFineDistinta",params).subscribe((dataRistampa: { codEsito: number,domandaConferma:string }) => {
              this.listaBobine=[];
            });
          },
          reject: () => {
      
          }
        });
      }
    });  
  
    
  }

}
