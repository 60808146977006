import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { GlobalServiceService } from 'src/app/services/global-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styles: [`
        :host ::ng-deep .pi-eye,
        :host ::ng-deep .pi-eye-slash {
            transform:scale(1.6);
            margin-right: 1rem;
            color: var(--primary-color) !important;
        }
    `]
})
export class LoginComponent  implements OnInit {

    valCheck: string[] = ['remember'];
    userName: string="";
    password: string="";
    message:string | null="";
    version='';
    constructor(public layoutService: LayoutService,
        public globalService:GlobalServiceService,
        private router:Router,
        private route: ActivatedRoute,
        private http: HttpClient
        ) { }
        
    ngOnInit(): void {

        if (this.route.snapshot.paramMap.get('desError')) {
            this.message=this.route.snapshot.paramMap.get('desError');
        }
        this.globalService.getVersion();
      
    }
    dologin(){
        let params={
            userName:this.userName,
            password:this.password
        };
        this.globalService.post("login",params).subscribe(data => {
            this.message="";
            if(data.desEsito=='OK'){
                data.datiUserName.userName=this.userName;
                localStorage.setItem('datiUserName',JSON.stringify( data.datiUserName));
                localStorage.setItem('idSessione',  data.idSessione);
                this.globalService.idSessione=data.idSessione;
                this.globalService.datiUserName=data.datiUserName;
                if(this.globalService.datiUserName){
                    this.globalService.bilancia=data.datiUserName.codBilancia;
                    this.globalService.linea=data.datiUserName.codLinea;
                    this.globalService.stampante=data.datiUserName.codStampante;
                }
                 
                this.router.navigate(['/']);
            } else {
                this.message=data.desEsito;
            }   
         
        });  
    }
   
}
