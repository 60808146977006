<div class="grid" >
	<div class="col-12 md:col-6">
		<div class="card p-fluid">
			<h5>{{titolo}}</h5>
			<div class="field grid">
				<label htmlFor="name3" class="col-3">Barcode</label>
				<div class="col-9">
					<input #barcodefield  
                    class="barcodeinput" 
                    (focus)="onFocus()" (blur)="onBlur()"
                    (window:keypress)="onKey($event)"  autofocus 
                    type="text" pInputText [(ngModel)]="globalservice.barcode" />
				</div>
			</div>
		</div>
	</div>
</div>
<app-showerror  ></app-showerror>

<p-confirmDialog icon="pi pi-exclamation-triangle" [acceptLabel]="'yes'" [rejectLabel]="'No'" [closable]="false" [acceptButtonStyleClass]="'p-button-text'" [rejectButtonStyleClass]="'p-button-text p-button-danger'"></p-confirmDialog>

  