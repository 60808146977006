import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalServiceService } from 'src/app/services/global-service.service';

@Component({
  selector: 'app-cambiostato',
  templateUrl: './cambiostato.component.html',
  styleUrls: ['./cambiostato.component.scss']
})
export class CambiostatoComponent implements OnInit {
  @ViewChild('barcodefield') barcodefield!: ElementRef;
  @ViewChild('inputncfield') inputncfield!: ElementRef;
  
  stato:string='';
  pesoNetto:any='';
  isBarCodeFocus:boolean=false;
  inputCodiceNC:boolean=false;
  activeFunctionParams:any;
  codiceNC:string="";
  constructor(
    public globalservice:GlobalServiceService,
    private router:Router,
  ) { }

  ngOnInit(): void {
    this.globalservice.barcode='';
    this.globalservice.setActiveFunction(5);
    setTimeout(()=>{ 
      this.barcodefield.nativeElement.focus();
    },100);  
  }
  
  cambiostato(){
    let params={
      idSessione:localStorage.getItem('idSessione'),
      Codice:this.globalservice.barcode,
      StatoCorrente:this.activeFunctionParams.StatoCorrente,
      StatoRichiesto:this.activeFunctionParams.StatoRichiesto,
      codiceNC:this.codiceNC
    }
    this.globalservice.post("CambioStatoBobina",params).subscribe((data) => {
      if(data.codEsito==1){
        this.globalservice.msgsError =data.DesEsito;
        this.globalservice.showError=true;
      } else {
     
        if(data.inputCodiceNC){
          this.inputCodiceNC=true;
          setTimeout(()=>{ 
            this.inputncfield.nativeElement.focus();
          },400);  
        } else {
          let params={
            idSessione:localStorage.getItem('idSessione'),
            Codice:this.globalservice.barcode,
            StatoCorrente:this.activeFunctionParams.StatoCorrente,
            StatoRichiesto:this.activeFunctionParams.StatoRichiesto,
            CodiceNC:''
          }
          this.globalservice.post("ConfermaCambioStatoBobina",params).subscribe((dataconferma: { codEsito: number; }) => {
            if(dataconferma.codEsito==1){
              this.globalservice.msgsError =data.DesEsito;
              this.globalservice.showError=true;
            } else {
              this.pesoNetto='';
              this.stato='';
              this.globalservice.barcode='';
              this.codiceNC='';
              this.inputCodiceNC=false;
            }
          });  
        }
      }
    });  
  }

  conferma(what:string){
    let activeFunctionParams:any;
    if(what=='dapan'){
      this.activeFunctionParams={
        StatoCorrente:'P',
        StatoRichiesto:'N'
      };
    }
    if(what=='dapadanapn'){
      this.activeFunctionParams={
        StatoCorrente:'N',
        StatoRichiesto:'P'
      };
    }
    if(what=='dapnah'){
      this.activeFunctionParams={
        StatoCorrente:'P',
        StatoRichiesto:'H'
      };
    }
    this.cambiostato();
  
  }

  onKey(event: any) {
    if (event.key === 'Enter') {
      this.globalservice.validateBarcode(5)?.subscribe((data: { codEsito: number;roll:any, barcodeType: number; })=>{
        if((data.codEsito!=1)&&( data.barcodeType==6)){
          let params={
            idSessione:localStorage.getItem('idSessione'),
            barcode:this.globalservice.barcode
          };
          this.pesoNetto=data.roll.pesoNetto;
          this.stato=data.roll.statoCorrente;

        }
      });
    } else {
      this.globalservice.barcode=event.target.value;
    }
  }

  onKeyNC(event: any) {
    if (event.key === 'Enter') {
      let params={
        idSessione:localStorage.getItem('idSessione'),
        barcode:this.codiceNC
      }
      this.globalservice.validateBarcode(5,params)?.subscribe((data: { codEsito: number;roll:any, barcodeType: number; })=>{
        if((data.codEsito!=1)&&( data.barcodeType==10)){
          this.globalservice.setActiveFunction(10);
          setTimeout(()=>{  
            this.cambiostato();
           
          },500);  
        }
      });
    } else {
      this.inputncfield=event.target.value;
    }
  }



  onBlur(){
    if(!this.inputCodiceNC)
      this.barcodefield.nativeElement.focus();
  
  }

  onBlurNC(){
    if(this.inputCodiceNC)
      this.inputncfield.nativeElement.focus();
   
  }

}
