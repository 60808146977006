import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class GlobalServiceService {
  bilancia:string='-';
  pesatore:string='-';
  stampante:string='-';
  linea:string='-';
  barcode:string="";
  showError:boolean=false;
  titoloalert:string='Attenzione';
  msgsError:string="";
  clientVersion='';
  apiUrl="";
  codiceLingua='';
  idSessione='';
  datiUserName:any;
  labels:any[]=[];
  result:any;
  version:string='';

  constructor(private http: HttpClient,  private router:Router) {
    if(localStorage.getItem('datiUserName')){
      this.datiUserName=JSON.parse(localStorage.getItem('datiUserName')!);
      if(this.datiUserName){
        this.bilancia=this.datiUserName.codBilancia;
        this.linea=this.datiUserName.codLinea;
        this.stampante=this.datiUserName.codStampante;
      }
     
    }

    if(localStorage.getItem('idSessione')){
      this.idSessione=localStorage.getItem('idSessione')!;
    }
 
    

    let par={
      isSessione:localStorage.getItem('idSessione'),
      codiceLingua:this.codiceLingua
    }
    this.http.get('/assets/environment/environment.json').subscribe((res:any) => {
      this.apiUrl=res.apiUrl;
      
      this.clientVersion = res.clientVersion;
        this.http.post<any>(res.apiUrl+'GetTranslations',par).subscribe((data:any)=>{
        if(data.lingua){
          this.codiceLingua=data.lingua.codLingua;
          this.labels=data.lingua.messaggi;
      
        }
      });
    });
  }

  public t(trad:string){
    let strArr=trad.split("|");
    if(strArr.length==1){
      return trad;
    }
   
    let s=this.labels.filter((a)=>a.code==strArr[1]);
    if(s.length>0){
      return s[0].description;
    }  else {
      return strArr[0];
    }
  }
   
  logout(){
    let params={
      idSessione:localStorage.getItem('idSessione')
    };
    this.post("logout",params).subscribe((data) => {
      if(data.codEsito!=0){
        this.msgsError =data.desEsito;
        this.showError=true;
        setTimeout(() => {
          this.showError=false;
          localStorage.removeItem('idSessione');
          localStorage.removeItem('pesatore');
          this.router.navigate(['/login']);
        }, 4000);
      } else {
        localStorage.removeItem('idSessione');
        localStorage.removeItem('pesatore');
        this.router.navigate(['/login']);
      }
     
    });
  }


  validateBarcode(activeFunction:number,parametri:any={}){
    let params;
    if(parametri.barcode){
      params={
        idSessione:localStorage.getItem('idSessione'),
        barcode:this.barcode
      }
    } else {
      if(this.barcode==''){
        return;
      }  
      params={
        idSessione:localStorage.getItem('idSessione'),
        barcode:this.barcode
      }
    }

   
    return this.post("validateBarcode",params).pipe(map((data) => {
    
      

      if(data.codEsito==1){
        
        this.msgsError =data.desEsito;
        this.showError=true;
        return data;

      } else {
        if(data.barcodeType){
          if(data.barcodeType==2){
            this.stampante=this.barcode;
            localStorage.setItem('stampante',JSON.stringify( this.stampante));
            this.barcode='';
            return data;
          }
          if(data.barcodeType==3){
            this.bilancia=this.barcode;
            localStorage.setItem('bilancia',JSON.stringify( this.bilancia));
            this.barcode='';
            return data;
          }
          if(data.barcodeType==4){
            this.bilancia=this.barcode.substring(1);
            localStorage.setItem('bilancia',JSON.stringify( this.bilancia));
            return data;
          }
          if(data.barcodeType==5){
            this.pesatore=data.weigher.fullName;
            localStorage.setItem('pesatore',JSON.stringify( this.pesatore));
            this.barcode='';
            return data;
          }
        
     
          return data;
     
        } else {
            this.msgsError =data.desEsito;
            this.showError=true;
            this.barcode='';
            return null;
        }
      }
    }));
   
  }

  

  setActiveFunction(functionNumber:number){
    let params={
      activeFunction:functionNumber,
      idSessione:localStorage.getItem('idSessione')
    };
    this.post("SetActiveFunction",params).subscribe(data => {
      if(data.codEsito!="0"){
        this.msgsError =data.desEsito;
        this.showError=true;
      }
    });
  }
 
  post(api:string,params: any) {
  

    const url = this.apiUrl + api;
    return this.http.post<any>(url, params,  {}).pipe(map(result => {
      return result;
    }, (error: any) => {
      console.error(error);
    }));

  }
  getVersion(){
    this.http.get('/assets/environment/environment.json').subscribe((res:any) => {
      this.apiUrl=res.apiUrl;
      this.post("GetVersion",{}).subscribe(data => {
          this.version="Frontend ver.:"+res.clientVersion;
          this.version+=" Backend ver.:"+data.versione;
      });
    });
  }
}
