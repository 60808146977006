<div class="layout-topbar" style="padding-left:10px;padding-right:10px;">
  
    <div class="grid" style="margin-top:5px;">
        <div class="col-1">
            <i style="margin-top:12px;" style="float:left;margin-top:20px;font-size:32px;" class="pi pi-fw pi-home" routerLink="/" ></i>
      
        </div>
      
        <div class="col-11"style="text-align:right;">
            <div style="padding-left:10px;margin-top:12px;font-weight: 700;;">
                <div class="grid">
                    <div class="col-3">
                        Linea:<br> {{globalservice.linea}}
                    </div>
                    <div class="col-3">
                        Bilancia: {{globalservice.bilancia}}
                    </div>
                    <div class="col-3">
                        Stampante: {{globalservice.stampante}}
                    </div>
                    <div class="col-3">
                        Pesatore: {{globalservice.pesatore}}
                    </div>

                </div>
            
            </div>
        </div>
        
    </div>
   
</div>
