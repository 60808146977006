

<div  *ngIf="showDialogSeparatori">
    <div class="grid" >
        <div class="col-12 md:col-6">
            <div class="card p-fluid">
                <h5>SEPARATORI</h5>
                <div class="field grid">
                    <label class="col-6"   for="codiceSeparatore">Codice separatore</label>
                    <div class="col-6">
                    <input type="text" #codiceSeparatoreField  pInputText [(ngModel)]="codiceSeparatore">
                    </div>
                </div>
              
                <div class="field grid">
                    <label class="col-6" for="numeroSeparatori">{{globalservice.t("Numero separatori")}}</label>
                
                    <div class="col-6">
                        <p-inputNumber [(ngModel)]="numeroSeparatori" 
                        [min]="0" [max]="100"></p-inputNumber> 
                    </div>
                </div>
                <div class="field grid">
                    <label class="col-6" for="taraSeparatore">Tara separatore</label>
                    <div class="col-6">
                        <p-inputNumber [(ngModel)]="taraSeparatore" 
                        [min]="0" [max]="100"></p-inputNumber> 
                    </div>
                </div>
                <div class="field grid">
                    <div class="col-6">
                        <p-button icon="fas fa-circle-play"  label="OK"  (click)="ConfermaSeparatori()"
                        styleClass="p-button-text p-button-success"></p-button>
                    </div>
                    <div class="col-6">
                        <p-button icon="fas fa-close" (click)="Annulla()" label="Annulla" 
                        styleClass="p-button-text p-button-danger" ></p-button>
                    </div>
                </div>
              
               
            </div>
        </div>
    </div>
</div>

<div class="grid">
	<div class="col-12 md:col-6">
        <h5>PESATURA</h5>
		<div class="card p-fluid">
			<div class="field grid">
				<label htmlFor="name3" class="col-3">Barcode</label>
				<div class="col-9">
					<input #barcodefield  
                    class="barcodeinput" 
                    (focus)="onFocus()" (blur)="onBlur()"
                    (window:keypress)="onKey($event)"  autofocus 
                    type="text" pInputText [(ngModel)]="globalservice.barcode" />
				</div>
                
			</div>
       
            <div *ngIf="bobinaobj">
                <div class="field grid">
                    <label  class="col-4">N. OP</label>
                    <div class="col-8 fout">
                        {{bobinaobj.numOP}}
                    </div>
                </div>
                <div class="field grid">
                    <label htmlFor="email3" class="col-4 ">Matricola</label>
                    <div class="col-8 fout">
                        {{bobinaobj.matricola}}
                    </div>
                </div>
                <div class="field grid">
                    <label class="col-4">Cod. Art.</label>
                    <div class="col-8 fout">
                        {{bobinaobj.codArticolo}}
                    </div>
                </div>
                <div class="field grid">
                    <label class="col-4">Data cons.</label>
                    <div class="col-8 fout">
                        {{bobinaobj.dataConsegna}}
                    </div>
                </div>
                <div class="field grid">
                    <label class="col-4">Qta ord.</label>
                    <div class="col-8 fout">
                        {{bobinaobj.qtaOrdinata}}
                    </div>
                </div>
           
           
                
                <div class="field grid">
                    <label htmlFor="email3" class="col-4">Qta pesata.</label>
                    <div class="col-8 fout">
                        {{bobinaobj.qtapesata}}
                    </div>
                </div>
                <div class="field grid">
                    <label  class="col-4">Peso letto</label>
                    <div class="col-8 fout">
                        {{bobinaobj.qtaPesata}}
                    </div>
                </div>
               
                <div class="field grid"  >
                    <div class="col-4">
                            <button pButton pRipple label="SEPARATORI" 
                            (click)="showDialogSeparatori=true"  class="p-button-lg"></button>
                        </div>
                    
                        <div class="col-4">
                            <button pButton pRipple label="PESATURA"  class="p-button-lg"></button>
                        </div>
                </div>
		</div>
	</div>
</div>
<app-showerror  ></app-showerror>

<p-confirmDialog icon="pi pi-exclamation-triangle" [acceptLabel]="'yes'" [rejectLabel]="'No'" [closable]="false" [acceptButtonStyleClass]="'p-button-text'" [rejectButtonStyleClass]="'p-button-text p-button-danger'"></p-confirmDialog>

  