<div class="grid" >
	<div class="col-12 md:col-6">
     
		<div class="card p-fluid">
            <h5>FORMAZIONE PALLET</h5>
			<div class="field grid">
				<label htmlFor="name3" class="col-3">Barcode</label>
				<div class="col-9">
					<input #barcodefield  
                    class="barcodeinput" 
                    (focus)="onFocus()" (blur)="onBlur()"
                    (window:keypress)="onKey($event)"  autofocus 
                    type="text" pInputText [(ngModel)]="globalservice.barcode" />
				</div>
			</div>
		</div>
	</div>
   
    <div class="col-12" *ngIf="listaBobine.length>0">
        <p-table [value]="listaBobine" [scrollable]="true">
            <ng-template pTemplate="header">
                <tr>
                    <th>Codice</th>
                    <th>Peso</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-bobina>
                <tr>
                    <td>{{ bobina.idBobina }}</td>
                    <td>{{ bobina.pesoNetto }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="col-12">
        <div *ngIf="listaBobine.length>0" class="field grid">
            <div class="col-6">
                <p-button label="Fine Distinta" (click)="FineDistinta()"
                styleClass="p-button-success"></p-button>
            </div>
            <div class="col-6"  style="text-align:right;">
                <p-button label="Annulla distinta" (click)="AnnullaDistinta()"
                styleClass="p-button-success"></p-button>
            </div>
        </div>
    </div>
    
</div>
<app-showerror  ></app-showerror>

<p-confirmDialog icon="pi pi-exclamation-triangle" [acceptLabel]="'yes'" [rejectLabel]="'No'" [closable]="false" [acceptButtonStyleClass]="'p-button-text'" [rejectButtonStyleClass]="'p-button-text p-button-danger'"></p-confirmDialog>

  