<p-dialog header="Error" 
          [(visible)]="globalservice.showError" 
          [draggable]="false"
          [resizable]="false"
          [closable]="true"
          [maximizable]="false"
          [modal]="true">

  <div class="p-grid">
    <div class="p-col-12" >
        {{globalservice.msgsError}}
    </div>
  </div>
  <ng-template pTemplate="footer">
    <p-button icon="fas fa-close" (click)="globalservice.showError=false" label="Close" 
     styleClass="p-button-text p-button-danger" ></p-button>
  </ng-template>
</p-dialog>

