import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];

    constructor(public layoutService: LayoutService) { }

    ngOnInit() {
        this.model = [
            {
                label: 'Home',
                items: [
                    { label: 'HOME', icon: 'pi pi-fw pi-home', routerLink: ['/'] },
                    { label: 'PESATURA', icon: 'pi pi-fw pi-home', routerLink: ['/pesatura'] },
                    { label: 'CANCELLAZIONE PESATUTA', icon: 'pi pi-fw pi-home', routerLink: ['action/cancellapesatura'] },
                    { label: 'RISTAMPA ETICHETTA', icon: 'pi pi-fw pi-home', routerLink: ['action/ristampaetichetta'] },
                    { label: 'FORMAZIONE PALLET', icon: 'pi pi-fw pi-home', routerLink: ['/formazionepallet'] },
                    { label: 'CAMBIO STATO BOBINA', icon: 'pi pi-fw pi-home', routerLink: ['/cambiostato'] },
                    { label: 'RISTAMPA DISTINTA', icon: 'pi pi-fw pi-home', routerLink: ['action/ristampadistinta'] },
                    { label: 'RISTAMPA PALLET', icon: 'pi pi-fw pi-home', routerLink: ['action/ristampapallet'] },
                    { label: 'LOGOUT', icon: 'pi pi-fw pi-home', routerLink: ['/logout'] }
                ]
            },
     
          
            
        ];
    }
}
