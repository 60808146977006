import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalServiceService } from 'src/app/services/global-service.service';
import { ConfirmationService, MessageService, ConfirmEventType } from 'primeng/api';

@Component({
  selector: 'app-altreazioni',
  templateUrl: './altreazioni.component.html',
  styleUrls: ['./altreazioni.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class AltreazioniComponent implements OnInit {
  @ViewChild('barcodefield') barcodefield!: ElementRef;

  
  isBarCodeFocus:boolean=false;
  activeFunction:number=0;
  titolo:string="";
  action:string | null ='';
  constructor(
    public globalservice:GlobalServiceService,
    private route: ActivatedRoute,
    private router:Router,
    private confirmationService: ConfirmationService, 
    private messageService: MessageService) {}

  ngOnInit(): void {
    this.globalservice.barcode='';
    this.action=this.route.snapshot.paramMap.get('actionid');
    if(this.action=='ristampaetichetta'){
      this.activeFunction=3;
      this.titolo="RISTAMPA ETICHETTA BOBINA";
    }
    if(this.action=='cancellapesatura'){
      this.activeFunction=2;
      this.titolo="CANCELLA PESATURA";
    }
    if(this.action=='ristampadistinta'){
      this.activeFunction=6;
      this.titolo="RISTAMPA DISTINTA";
    }
    if(this.action=='ristampapallet'){
      this.activeFunction=7;
      this.titolo="RISTAMPA ETICHETTA PALLET";
    }
    this.globalservice.setActiveFunction(this.activeFunction);
    setTimeout(()=>{ 
      this.barcodefield.nativeElement.focus();
    },100);  
  }
  

  onKey(event: any) {
    if (event.key === 'Enter') {
      this.globalservice.validateBarcode(this.activeFunction)?.subscribe(data=>{
        if((data.codEsito!=1)&&( data.barcodeType==2)){
          this.globalservice.barcode='';
          return;
        } 
        if((data.codEsito!=1)&&( data.barcodeType==6)){
         
          if(this.action=='ristampaetichetta'){
            let params={
              idSessione:localStorage.getItem('idSessione'),
              tipoDocumento:1,
              codice:this.globalservice.barcode
            };
            this.globalservice.post("Ristampa",params).subscribe((dataconferma: { codEsito: number,domandaConferma:string }) => {
              if(dataconferma.codEsito==0){
                this.confirmationService.confirm({
                  header: this.globalservice.titoloalert,
                  message: dataconferma.domandaConferma,
                  accept: () => {
                    this.globalservice.post("ConfermaRistampa",params).subscribe((dataRistampa: { codEsito: number,desEsito:string }) => {
                      if(dataRistampa.codEsito==0){
                        this.router.navigate(['/']);
                      } else {
                        this.globalservice.msgsError =dataRistampa.desEsito;
                        this.globalservice.showError=true;
                      }
                    });
                  },
                  reject: () => {
                    this.router.navigate(['/']);
                  }
                });
              }
            });  
          }
          if(this.action=='cancellapesatura'){
            let params={
              idSessione:localStorage.getItem('idSessione'),
              codice:this.globalservice.barcode
            };
            this.globalservice.post("CancellaPesata",params).subscribe((dataconferma: { codEsito: number,domandaConferma:string }) => {
              if(dataconferma.codEsito==0){
                this.confirmationService.confirm({
                  header: this.globalservice.titoloalert,
                  message: dataconferma.domandaConferma,
                  accept: () => {
                    this.globalservice.post("ConfermaCancellaPesata",params).subscribe((dataRistampa: { codEsito: number,desEsito:string }) => {
                      if(dataRistampa.codEsito==0){
                        this.router.navigate(['/']);
                      } else {
                        this.globalservice.msgsError =dataRistampa.desEsito;
                        this.globalservice.showError=true;
                      }
                    });
                  },
                  reject: () => {
                    this.globalservice.setActiveFunction(1);
                    this.router.navigate(['/']);
                  }
                });
              }
            });  
          }
          if(this.action=='ristampadistinta'){
            let params={
              idSessione:localStorage.getItem('idSessione'),
              tipoDocumento:2,
              codice:this.globalservice.barcode
            };
            this.globalservice.post("Ristampa",params).subscribe((dataconferma: { codEsito: number,domandaConferma:string }) => {
              if(dataconferma.codEsito==0){
                this.confirmationService.confirm({
                  header: this.globalservice.titoloalert,
                  message: dataconferma.domandaConferma,
                  accept: () => {
                    this.globalservice.post("ConfermaRistampa",params).subscribe((dataRistampa: { codEsito: number,desEsito:string }) => {
                      if(dataRistampa.codEsito==0){
                        this.globalservice.barcode='';
                        this.router.navigate(['/']);
                      } else {
                        this.globalservice.msgsError =dataRistampa.desEsito;
                        this.globalservice.showError=true;
                      }
                    });
                  },
                  reject: () => {
                    this.globalservice.barcode='';
                    this.router.navigate(['/']);
                  }
                });
              }
            });  
          }
          if(this.action=='ristampapallet'){
            let params={
              idSessione:localStorage.getItem('idSessione'),
              tipoDocumento:3,
              codice:this.globalservice.barcode
            };
            this.globalservice.post("Ristampa",params).subscribe((dataconferma: { codEsito: number,domandaConferma:string }) => {
              if(dataconferma.codEsito==0){
                this.confirmationService.confirm({
                  header: this.globalservice.titoloalert,
                  message: dataconferma.domandaConferma,
                  accept: () => {
                    this.globalservice.post("ConfermaRistampa",params).subscribe((dataRistampa: { codEsito: number,desEsito:string }) => {
                      if(dataRistampa.codEsito==0){
                        this.router.navigate(['/']);
                      } else {
                        this.globalservice.msgsError =dataRistampa.desEsito;
                        this.globalservice.showError=true;
                      }
                    });
                  },
                  reject: () => {
                    this.router.navigate(['/']);
                  }
                });
              }
            });  
          }
    
          
        }
        
      });
    } else {
      this.globalservice.barcode=event.target.value;
    }
  }

  onFocus(){
    this.isBarCodeFocus=true; 
  }

  onBlur(){
    this.barcodefield.nativeElement.focus();
    this.isBarCodeFocus=false;  
  }

}

