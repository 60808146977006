import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { GlobalServiceService } from 'src/app/services/global-service.service';

@Component({
  selector: 'app-pesatura',
  templateUrl: './pesatura.component.html',
  styleUrls: ['./pesatura.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class PesaturaComponent implements OnInit {

  isBarCodeFocus:boolean=false;
  pesata:boolean=false;
  codiceSeparatore:string='';
  numeroSeparatori:number=0;
  taraSeparatore:number=0;
  matricolabobina:any='';
  showDialogSeparatori:boolean=false;
  bobinaobj:any;
  
  @ViewChild('barcodefield') barcodefield!: ElementRef;
  @ViewChild('codiceSeparatoreField') codiceSeparatoreField!: ElementRef;
  
  /*
  @HostListener('document:keypress', ['$event'])


  keyEvent(event: KeyboardEvent) {
   
    if(this.isBarCodeFocus){
      return;
    }
      if (event.key === 'Enter') {
        this.go();
      } else {
     
        this.barcode +=  event.key;
  
      }
  }
*/
  constructor(
    public globalservice:GlobalServiceService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this.globalservice.barcode='';
    this.globalservice.setActiveFunction(1);

    setTimeout(()=>{ 
      this.barcodefield.nativeElement.focus();
    },100);  
  }


  
  onKey(event: any) {
    if (event.key === 'Enter') {
      this.globalservice.validateBarcode(1)?.subscribe((data: any)=>{
        if((data.codEsito!=1)&&( data.barcodeType==6)){
          let params={
            idSessione:localStorage.getItem('idSessione'),
            barcode:this.globalservice.barcode
          };
          if(data.roll){
            this.bobinaobj={
              numOP:data.roll.numOP,
              matricola:data.roll.matricola,
              codArticolo:data.roll.codArticolo,
              dataConsegna:data.roll.dataConsegna,
              qtaOrdinata:data.roll.qtaOrdinata,
              qtaPesata:data.roll.qtaPesata,
              statoCorrente:data.roll.statoCorrente
            }
          }
       
          this.globalservice.post("RollReweight",params).subscribe((datRR:any) => {
            if(datRR.codEsito==1){
              this.reset(data,params);
            } else {
              if(datRR.rollReweight){
                this.confirmationService.confirm({
                  header: this.globalservice.titoloalert,
                  message: datRR.confirmation,
                  accept: () => {
                
                  },
                  reject: () => {
                    this.reset(data,params);
                  }
                });
              }
            }
          });
          
        }
      });
    } else {
      this.globalservice.barcode=event.target.value;
    }
  }

  reset(data:any,params:any){
    this.globalservice.post("RollReweight",params).subscribe((dataReset:any) => {
      this.globalservice.msgsError =data.desEsito;
      this.globalservice.showError=true;
      this.globalservice.barcode='';
    });
  }

  onFocus(){
    this.isBarCodeFocus=true; 
  }

  onBlur(){
    if(this.showDialogSeparatori) return;

    this.barcodefield.nativeElement.focus();
    this.isBarCodeFocus=false; 
    
  }


  apriseparatori(){
    this.showDialogSeparatori=true;
  }
  
  ConfermaSeparatori(){
    this.showDialogSeparatori=false;
    this.barcodefield.nativeElement.focus(); 
  }

  Annulla(){
    this.showDialogSeparatori=false;
    this.barcodefield.nativeElement.focus();
  }



}
