<div class="grid" >
	<div class="col-12 md:col-6">
		<div class="card p-fluid">
			<h5>CAMBIO STATO</h5>
			<div class="field grid">
				<label htmlFor="name3" class="col-3">Barcode</label>
				<div class="col-9">
					<input #barcodefield  
                    class="barcodeinput" 
                    (blur)="onBlur()"
                    (keypress)="onKey($event)"  autofocus 
                    type="text" pInputText [(ngModel)]="globalservice.barcode" />
				</div>
			</div>

			<div *ngIf="inputCodiceNC" class="field grid">
				<label htmlFor="name3" class="col-3">Codice NC</label>
				<div class="col-9">
					<input #inputncfield  
                    class="barcodeinput" 
                     (blur)="onBlurNC()"
                    (keypress)="onKeyNC($event)"  autofocus 
                    type="text" pInputText [(ngModel)]="codiceNC" />
				</div>
			</div>
            <div class="grid" style="margin-top:10px;" *ngIf="!inputCodiceNC">

                <div class="col-6">
                   <h5>Peso: {{pesoNetto}}</h5>
                </div>
                <div class="col-6">
                    <h5>Stato: {{stato}}</h5>
                </div>
                <div class="col-12">
                    N = NON CONFORME
                </div>
                <div class="col-12">
                    P = PESATA
                </div>
                <div class="col-12">
                    C = CONFORME
                </div>
                <div class="col-12">
                    H = SCARTO
                </div>
            </div>
           
            <div *ngIf="!inputCodiceNC" class="field grid" style="margin-top: 15px;">
                <div class="col-4" style="padding:5px;">
                    <p-button label="Da P a N" [disabled]="stato!='P'" (click)="conferma('dapan')" 
                    styleClass="p-button-success"></p-button>
                </div>
                <div class="col-4" style="padding:5px;">
                    <p-button label="Da N a P" [disabled]="stato!='N'" (click)="conferma('danap')" 
                    styleClass="p-button-success"></p-button>
                </div>
                <div class="col-4" style="padding:5px;">
                    <p-button [disabled]="stato!='P' && stato!='N'" label="Da PN a H"  (click)="conferma('dapnah')"
                    styleClass="p-button-success"></p-button>
                </div>
            </div>
		</div>
	</div>
</div>
<app-showerror  ></app-showerror>



