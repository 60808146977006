import { Component, Input, OnInit } from '@angular/core';
import { GlobalServiceService } from 'src/app/services/global-service.service';

@Component({
  selector: 'app-showerror',
  templateUrl: './showerror.component.html',
  styleUrls: ['./showerror.component.scss']
})
export class ShowerrorComponent implements OnInit {
  

  constructor( public globalservice:GlobalServiceService) { }

  ngOnInit(): void {
    
  }

}
