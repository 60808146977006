<div class="surface-ground flex align-items-center justify-content-center  min-w-screen overflow-hidden">
    <div class="flex flex-column align-items-center justify-content-center" style="margin-top:20px;">
        <img src="assets/images/logo-sipi.png" alt="Sipi Logo" class="mb-5 w-6rem flex-shrink-0">                
        <div>
            <div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius:53px">
                <div class="text-center mb-5">
                   
                    <div class="text-900 text-3xl font-medium mb-3">{{globalService.t("Benvenuto|311")}}</div>
                    <div class="text-600  mb-3">{{globalService.version}}</div>
                </div>

                <div>
                    <label for="email1" class="block text-900 text-xl font-medium mb-2">{{globalService.t("Username|312")}}</label>
                    <input id="email1"  [(ngModel)]="userName"  type="text" placeholder="Username" pInputText class="w-full md:w-30rem mb-5"
                     style="padding:1rem">

                    <label for="password1" class="block text-900 font-medium text-xl mb-2">{{globalService.t("Password|313")}}</label>
                    <p-password id="password1" [(ngModel)]="password"  [feedback]="false" [toggleMask]="true" 
                    styleClass="w-full md:w-30rem mb-5" inputStyleClass="w-full p-3 md:w-30rem"></p-password>
                    <button pButton (click)="dologin()" pRipple label="LOGIN" class="w-full p-3 text-xl"></button>
                </div>
                <div style="color:red;margin-top:10px;font-size:120%;text-align:center;width:100%;">
                    {{message}}
                </div>
            </div>
        </div>
    </div>
</div>
<app-showerror  ></app-showerror>