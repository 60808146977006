import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppLayoutComponent } from "./layout/app.layout.component";
import { PesaturaComponent } from './components/pesatura/pesatura.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { CambiostatoComponent } from './components/cambiostato/cambiostato.component';
import { FormazionepalletComponent } from './components/formazionepallet/formazionepallet.component';
import { LogoutComponent } from './components/logout/logout.component';
import { AltreazioniComponent } from './components/altreazioni/altreazioni.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppLayoutComponent,canActivate: [AuthGuard],
                
                children: [
                    { path: 'pesatura', component: PesaturaComponent,canActivate: [AuthGuard] },
                    { path: 'cancellapesatura', component: AltreazioniComponent,canActivate: [AuthGuard] },
                    { path: 'cambiostato', component: CambiostatoComponent,canActivate: [AuthGuard] },
                    { path: 'formazionepallet', component: FormazionepalletComponent,canActivate: [AuthGuard] },
                    { path: 'action/:actionid', component: AltreazioniComponent,canActivate: [AuthGuard] },
                    { path: '', loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule) },
                  ]
            },
            { path: 'login', component: LoginComponent,  },
            { path: 'logout', component: LogoutComponent,  },
            { path: 'login/:desError', component: LogoutComponent,  },
          
        ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
