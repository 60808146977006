import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Router } from '@angular/router';

@Injectable({
  providedIn: "root",
})

export class ResponseInterceptorService implements HttpInterceptor{
  curl="";
  constructor(
    private router:Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.curl=req.url.toLowerCase();
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if((event['body'])&&(event['body']['suono']!='')){
            this.playAudio(event['body']['suono']);
          }
          if((event['body'])&&(event['body']['codEsito']==2)){
            localStorage.removeItem('idSessione');
            localStorage.removeItem('pesatore');
            this.router.navigate(['/login']);
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
          return throwError(error);
      })
    );
  }
    
  playAudio(file:string){
    let audio = new Audio();
    audio.src = "assets/audio/"+file;
    audio.load();
    audio.play();
  }

}
